import React from 'react'
import { registerStickyPolyfillElement, unregisterStickyPolyfillElement }
  from '../../../lib/App/common/helper'
import Rendering from '../../../lib/App/common/konfigurator/compontents/Rendering'
import Image from '../../../lib/App/common/Image'

export class RenderingPergamin extends Rendering {
  componentDidMount () {
    registerStickyPolyfillElement('.sticky-rendering')
  }

  componentWillUnmount () {
    unregisterStickyPolyfillElement('.sticky-rendering')
  }

  render () {
    return (
      <figure className={'image sticky-rendering'}>
        <div class='fit-container'>
          <Image src={this.props.imagePath} alt={'IMAGE ' + this.props.name} ratio='r1.5' height='500p' className={'fit'} />
        </div>
      </figure>
    )
  }
}

export default RenderingPergamin
