import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import Steps from '../App/common/Steps/Steps'
import Highlights from '../App/common/Hightlights/Highlights'
import Konfigurator from '../App/common/Konfigurator/Konfigurator'
import KonfiguratorNavigation from '../App/common/Konfigurator/components/KonfiguratorNavigation'
import { KonfiguratorPage, mapStateToProps, mapDispatchToProps } from '../lib/KonfiguratorPage'
import StickyNotePrice from '../App/common/StickyNote/StickyNotePrice'

export class KonfiguratorPagePergamin extends KonfiguratorPage {
  render () {
    const nav = this.getNav()
    const { frontendProperty = {} } = this.props.konfigurationV
    return (
      <Fragment>
        <Steps step={this.props.step} highestStep={frontendProperty.highestStep}
          konfigurationVId={this.props.konfigurationV.konfigurationVId} />
        <Highlights />
        <section className='group bk-options'>
          <form className='group' noValidate>
            <fieldset className='bk-options-group group row'>
              <Konfigurator konfigurationVId={this.state.konfigurationVId}
                isReadOnly={this.props.konfigurationV.readOnly}
                kategorieClasses={this.props.kategorieClasses} />
            </fieldset>
            <KonfiguratorNavigation {...nav} />
          </form>
        </section>
        <StickyNotePrice />
      </Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(KonfiguratorPagePergamin)
